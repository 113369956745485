export default {
    methods: {
        getActsShutters(props) {
            return {
                'contract-form': {
                    name: 'contract-form',
                    title: this.$t('monte.modifier_contrat'),
                    cmpPath: 'components/Contract/ContractForm',
                    cmpRoot: false,
                    onOk: this.onShuttersSubmit,
                    props
                },
            }
        },
        setupContractForm(contract_id, from, mare_id=0) {
            const shutters = this.getActsShutters({
            	contract_id,
                from,
                mare_id
            })
            this.shutterPanel().open(shutters['contract-form'])
        },
    }
}