import { EventBus } from "EventBus"

export default {
    props: {
        shutterData: Object
    },
    data: () => ({
        _panel: null,
        reloadMethod: 'init_component', // Méthode de reload/reset du composant. Par défaut, init_component

        /* Pour la gestion d'ouverture / fermeture des panels, utilisés dans les composants */
        last_operation: '',
        last_row_id: 0,
    }),
    created() {
        if (this.shutterData && this.shutterData.name) {
            EventBus.$on(`ShutterPanel::open::${this.shutterData.name}`, this.onOpen)
            EventBus.$on(`ShutterPanel::close::${this.shutterData.name}`, this.onClose)
            
            const eventName = 'ShutterPanel::componentReady::'+this.shutterPanel()._uid
            EventBus.$emit(eventName, this.shutterData.name)
        }
        this.$emit(`ShutterPanel::refreshTree`)

    },
    beforeDestroy() {
        if (this.shutterData && this.shutterData.name) {
            EventBus.$off(`ShutterPanel::open::${this.shutterData.name}`, this.onOpen)
            EventBus.$off(`ShutterPanel::close::${this.shutterData.name}`, this.onClose)
        }
    },
    methods: {
        /**
         * Permet de récupérer l'instance du ShutterPanel
         * @returns VueComponent
         */
        shutterPanel() {
            if (this._panel) {
                return this._panel
            }

            // Chercher à travers tous les parents
            let panel = this.$parent

            // On vérifie que le conteneur du ShutterPanel trouvé n'est pas un enfant (isChild du ShutterPanel)
            while (panel && (panel.$options.name !== 'ShutterPanel')) {
                panel = panel.$parent
            }

            // Si on ne le trouve pas dans les parents, on cherche dans les enfants
            if(!panel) {
                const target = this.$children.find(child => child.$options.name === 'ShutterPanel')

                if(target) {
                    panel = target
                }
            }

            this._panel = panel
            return this._panel
        },

        /**
         * Confirme et ferme le volet
         * @param {Any} payload Paramètres passés à la callback onOk
         */
        ok(payload) {
            this.shutterPanel().ok(this.shutterData.name, payload)
        },

        /**
         * Annule et ferme le volet
         * @param {String} reason Paramètre optionel indiquant la raison de la fermeture du volet
         */
        cancel(reason=null) {
            this.shutterPanel().cancel(this.shutterData.name, reason)
        },

        /**
         * Callback appelée lors de l'ouverture d'un volet - appelée lors de l'event bus d'ouverture ciblé
         * Par défaut, vide. C'est au composant de l'override
         */
        onOpen() {
            return false
        },

        /**
         * Callback appelée lors de la fermeture d'un volet
         */
        onClose() {
            return false
        },

        /**
         * Action à exécuter lorsqu'on clique sur le bouton retour du HeaderTab
         * Par défaut, on ne garde que le premier volet
         */
        onBack() {
            if(this.last_operation) this.last_operation = ''
            if(this.last_row_id) this.last_row_id = 0

            if (this.shutterPanel().orderedShutters.length > 1) {
                return this.shutterPanel().keepLast()
            }
            window.history.back()
        }
    }
}
